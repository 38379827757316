/** @jsxImportSource @emotion/react */
import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";
import { IoIosWarning } from "react-icons/io";

import { Tooltip } from "~src/designSystem/atoms/Tooltip";

import { IInputProps, OldInputGoUseTextfield as InputNew } from "../OldInput";

export type IInputWithLabelProps = IInputProps & {
  Input?: React.FC<IInputProps>;
  className?: string;
  error?: string;
  label: string;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
};

/**
 * Wraps an input and adds an inset label to it.
 */
export const OldInputWithLabelDontUse: React.FC<IInputWithLabelProps> = ({
  Input = InputNew,
  className,
  error,
  label,
  rightIcon,
  // placeholder needs to have text in it by default to properly
  // handle its position
  placeholder = " ",
  children,
  ...inputProps
}: IInputWithLabelProps) => {
  return (
    <Wrapper className={className}>
      <Input error={error} placeholder={placeholder} {...inputProps} />
      {rightIcon !== undefined && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
      <Label error={error}>{label}</Label>
      {error !== undefined && (
        <Tooltip tooltipContent={error} placement="right">
          <IconContainer
            css={(theme: Theme) => css`
              color: ${theme.components.Input.icon.error};
            `}
          >
            <IoIosWarning style={{ fontSize: "20px" }} />
          </IconContainer>
        </Tooltip>
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  & > input {
    width: 100%;
    height: 50px;
    padding: 14px 20px 0;
    outline: none;
    transition: all 0.2s ease;
    color: ${(props) => props.theme.components.Input.text.default};

    /* this determines where the label goes after the input is selected */
    &:not(:placeholder-shown) ~ label,
    &:focus ~ label {
      transform: translateY(-10px);
      font-size: 11px;
    }
  }
`;

const RightIconWrapper = styled.div`
  position: absolute;
  right: 19px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.components.Input.icon.default};
`;

const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
`;

const Label = styled(motion.label)<{
  error?: string;
  isActive?: boolean;
}>`
  position: absolute;
  font-size: 13px;
  padding: 21px;
  margin-bottom: 4px;
  color: ${(p) => p.theme.components.Input.label.default};

  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;

  transition: all 0.4s ease;
  ${(p) => p.error !== undefined && `color: ${p.theme.components.Input.label.error};`}
`;
