/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";
import { Carousel } from "react-responsive-carousel";

import { ReallyOldParagraphDontUse } from "~src/designSystem/deprecated/Paragraph.old";

// TODO(md): Vendor these icons.
const contents = [
  {
    logoURL:
      "https://assets-global.website-files.com/5f36e1e9e7d0bde21a198879/5f4188f6b1f4687967e85db8_Intercom.svg",
    logoWidth: "80px",
    logoMarginBottom: "30px",
    quote: `Up until now, SaaS businesses had only two choices: scale fast while accepting significant debt or dilution, or bootstrap, while accepting less available cash flow and usually slower growth. Pipe is now the third option: scale without dilution.`,
    name: "Des Traynor",
    title: "Co-Founder",
    companyName: "Intercom",
  },
  {
    logoURL:
      "https://assets.website-files.com/60300fc89b79e8e2996ea1b1/60300fc89b79e83a3f6ea3b5_AngelList.svg",
    logoWidth: "80px",
    logoMarginBottom: "30px",
    quote: `Pitch customers, not investors, and let Pipe convert your contracts into instant, dilution-free financing.`,
    name: "Naval Ravikant",
    title: "Founder",
    companyName: "AngelList",
  },
  {
    logoURL:
      "https://assets.website-files.com/60300fc89b79e8e2996ea1b1/60300fc89b79e86a3f6ea3b6_DataRobot.svg",
    logoWidth: "80px",
    logoMarginBottom: "30px",
    quote: `Now more than ever, cash is king. Pipe meets this need by providing access to a new, non-dilutive financing option with the ability to attract and retain customers through flexible payment options. They say timing is everything, and Pipe’s time is now.`,
    name: "Dan Wright",
    title: "President & CEO",
    companyName: "DataRobot",
  },
] as const;

export const RightTestimonies: React.FC = () => {
  return (
    <CarouselWrapper>
      {/* // FIX: style */}
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={10000}
        infiniteLoop
        autoPlay
      >
        {contents.map((content) => {
          return (
            <div key={content.companyName} css={contentContainerStyles}>
              <img
                src={content.logoURL}
                width={content.logoWidth}
                alt={content.companyName}
                style={{
                  margin: `0 auto ${content.logoMarginBottom}`,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <ReallyOldParagraphDontUse
                  size="16px"
                  margin="0 0 42px 0"
                  weight="400"
                  color="#FFF"
                >
                  &ldquo;{content.quote}&rdquo;
                </ReallyOldParagraphDontUse>
                <ReallyOldParagraphDontUse size="18px" lineHeight="2.5" weight="600" color="#FFF">
                  {content.name}
                </ReallyOldParagraphDontUse>
                <ReallyOldParagraphDontUse
                  size="12px"
                  lineHeight="0"
                  weight="500"
                  color="#ffffff6e"
                >
                  {content.title}, {content.companyName}
                </ReallyOldParagraphDontUse>
              </div>
            </div>
          );
        })}
      </Carousel>
    </CarouselWrapper>
  );
};

const contentContainerStyles = css`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 40px 40px 60px;
`;

const CarouselWrapper = styled.div`
  z-index: 1000;
  .carousel-root {
    outline: none;
  }
`;
