import { IPageAuthorization } from "~src/shared/auth/AuthProvider";
import { IPipeRouter } from "~src/shared/requests/router";

import { makeAuthHOC } from "./makeAuthHOC";

/**
 * Allow anyone to view this page.
 */
export const PublicAuth = makeAuthHOC({
  name: "PublicAuth",
  apolloRouter: IPipeRouter.PUBLIC,
  authorization: IPageAuthorization.PUBLIC,
});
